import React from "react";
import logo from "./le_crunge.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div id="container"></div>
      </header>
    </div>
  );
}

export default App;
